/**
 * FILTROS
 */
.filters-wrap {
  margin: -10px 0 20px 0;
  color: #035ee6;

    .icofont-cloud-download{
      margin-left:8px;
    }

  .filter-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    > * + * {
      margin-left: 8px;
    }
  }

  @media (max-width: 575px) {
    margin-left: 3px;
    margin-top: 3px;
    margin-bottom: 10px;
  }

  i {
    font-size: 15pt;
    margin-right: 9px;
    cursor: pointer;

    &:hover {
      color: var(--blue);
    }

    &:focus, &:active {
      color: var(--light-blue);
    }

  }

  .dateFilter {
    display: inline-block;
    margin-top: 5px;
    & > div {
      display: inline-block;
      &:hover {
        color: var(--blue);
      }

      &:focus, &:active {
        color: var(--light-blue);
      }

      & > span {
        border-bottom: 1px solid #035ee6;
        &:nth-child(2):after {
          content: "-";
        }
      }
    }

    font-family: var(--lato), sans-serif;
    font-size: 10.5pt;
    line-height: 14.5pt;
    font-weight: 700;
    text-align: right;
    flex: none;

    i {
      float: left;
    }

    input {
      color: #035ee6;
      font-weight: 700;
      border: none;
      background: transparent;
      width: 48px;
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
      &.selected {
        width: 65px;
      }
    }
  }
}

.grilla-filter-badge {
  position: relative;
  display: inline-block;

  height: 24px;
  line-height: 24px;
  text-transform: uppercase;

  max-width: 320px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 7pt;

  padding: 0 10px 0 25px;
  margin: 0 5px 6px 0;

  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(16, 6, 159, 0.1);

  background-color: white;
  color: var(--soft-black);

  cursor: pointer;

  vertical-align: bottom;

  .icon-close {
    position: absolute;
    top: 6px;
    left: 9px;

    font-size: 9pt;
    font-weight: bold;
  }

  &:hover {
    color: var(--light-white);
    background-color: var(--soft-black);
    transition: all 0.2s linear;
  }

  &:first-child {
    margin-top: 7px;
  }
}

.filters-w-icons {
  display: flex;

  .mat-mdc-progress-spinner circle {
    stroke: #A4A4A4;
  }

  & > div > i {
    float: left;
    margin-top: 7px;
    @media (max-width: 330px) {
      margin-right: 6px;
    }
  }
}

::placeholder {
  color: #035ee6;
}
