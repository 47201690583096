/*=============================================
METRICAS
=============================================*/

ul.metrics {
  list-style: none;
  text-align: center;
  padding: 0;
  margin-left: -15px;
  width: calc(100% + 15px);

  li {
    min-width: 200px;
    max-width: 50%;
    margin-bottom: 10px;
    flex: 1;
    padding-left: 15px;
    padding-right: 0;

    @media (max-width: 575px) {
      min-width: 160px;
      max-width: 100%;
    }

    .metric-wrap {
      color: var(--soft-black);
      background-color: #ffffff;
      display: block;
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0px 1px 3px rgba(16, 6, 159, 0.1);

      h4,
      h2 {
        margin: 14px 0;
        padding: 0;
        text-transform: uppercase;
        font-family: var(--lato);
        font-size: 12pt;
        line-height: 12pt;
      }

      h4 {
        color: var(--light-blue);
        white-space: nowrap;
      }

      h3,
      .total-value {
        font-family: var(--lato);
        padding: 0;
        margin: 0;
        font-size: 16pt;
        line-height: 16pt;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .kpi-spinner {
        display: inline-flex;

        .mat-mdc-progress-spinner circle {
          stroke: #A4A4A4;
        }
      }

      .progress {
        height: 8px;
        background-color: var(--light-grey);

        .progress-bar {
          background-color: var(--light-blue);
        }
      }
    }
  }
}