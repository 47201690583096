@font-face {
    font-family: 'Ubuntu';
    font-style: light;
    font-weight: 300;
    src: url(./Ubuntu-Light.ttf) format('ttf');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: url(./Ubuntu-Regular.ttf) format('ttf');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: bold;
    font-weight: 500;
    src: url(./Ubuntu-Bold.ttf) format('ttf');
}
