/* You can add global styles to this file, and also import other style files */

// Angular Material:
@import './assets/fonts/Material_icons/material_icons.scss';
@import './assets/fonts/Ubuntu/ubuntu.scss';
@import './assets/fonts/Roboto/roboto.scss';
@import './assets/fonts/Roboto_Condensed/roboto_condensed.scss';
@import './assets/fonts/Lato/lato.scss';
@import './assets/scss/paginator.scss';
@import './assets/scss/modal.scss';
@import './assets/scss/filters.scss';
@import './assets/scss/metrics.scss';
@import './assets/scss/tables.scss';
@import './assets/scss/second-nav.scss';
@import './theme/agd-agro-default.scss';

// NGX-Emoji-Mart:
@import '@ctrl/ngx-emoji-mart/picker';

// Min. Supported Width:
html,
body {
  background-color: var(--light-white);
  min-width: 320px;
  min-height: 660px;
  font-family: 'Ubuntu', sans-serif !important;
  overflow-y: hidden;
}

.height-40px {
  height: 40px !important;
}

/* ---------- Scrollbar Styles ---------- */
$scrollbar-color: rgba(black, .25);
$scrollbar-track-color: var(--light);
$scrollbar-size: 8px;

// Firefox Style
@supports (-moz-appearance: none) {

  html,
  body {
    scrollbar-face-color: $scrollbar-color;
    scrollbar-track-color: $scrollbar-track-color;
    scrollbar-color: $scrollbar-color $scrollbar-track-color;
    scrollbar-width: thin;
  }
}

// Webkit Style
::-webkit-scrollbar {
  height: $scrollbar-size;
  width: $scrollbar-size * .75;
}

.scrollbar-x-sm::-webkit-scrollbar {
  height: $scrollbar-size * .75;
}

.scrollbar-y-lg::-webkit-scrollbar {
  width: $scrollbar-size;
}

::-webkit-scrollbar-track {
  background-color: $scrollbar-track-color;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
  border-radius: $scrollbar-size;

  &:hover {
    background-color: rgba($scrollbar-color, .4);
  }
}

.scrollbar-dark {
  &::-webkit-scrollbar-thumb {
    background-color: #787878;

    &:hover {
      background-color: #8f8f8f;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #323232;
  }
}

/* ---------- Scrollbar Styles End ---------- */
.capitalize {
  text-transform: capitalize !important;
}

// Main Content Wrappers
#main-wrap {
  overflow: hidden;
  height: 100%;
  height: 100dvh;
}

.content {
  height: calc(100dvh - 70px);
  //display: grid;
  align-content: start;
  overflow-x: auto;

  .content-page {
    padding: 30px;

    @media (max-width: 575px) {
      padding: 10px;

      .row.table-row {
        margin-left: -10px;
        margin-right: -10px;
      }
    }

    .box {
      background-color: #ffffff;
      display: block;
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0px 1px 3px rgba(16, 6, 159, 0.1);
    }
  }
}

.pointer-event {
  cursor: pointer;
}

.tooltip {
  font-size: 9pt;
}

.mat-mdc-progress-spinner[color='light'] circle {
  stroke: white;
}

/**
 * mat-menu y mat-select renderiza los elementos casi en la raíz del documento.
 * Por esto cualquier cambio de estilo que se desee hacer sobre el mismo hay
 * que definirlo globalmente.
 */
.mat-mdc-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-mdc-menu-item:focus {
  outline: none;
}

.hovereable-row {
  cursor: pointer;
  outline: none;

  &:focus {
    background-color: #ecf0f1;
  }

  &:hover {
    background-color: #ecf0f1;
    transition: all 0.3s linear;
  }

  &:active {
    background-color: #bdc3c7;
  }
}

.mat-select-panel mat-option.option-hide-checkbox {
  .mat-pseudo-checkbox {
    visibility: hidden;
  }
}

/**
 * Variables:
 * ----------
 * En el siguiente bloque se definen variables disponibles en toda la aplicación.
 * Especialmente útil si se necesita mantener una consistencia por ejemplo en los
 * colores utilizados, ya que un cambio de color significaría ir componente por
 * componente realizando un cambio donde aparece dicho color.
 *
 * A continuación un ejemplo de como utilizar una variable:
 *      .miClase {
 *          color: var(--variable-color);
 *          font-size: var(--texto-grande);
 *          padding: var(--espaciado-sm);
 *      }
 */

:root {
  --ubuntu: 'Ubuntu', sans-serif;
  --roboto: 'Roboto', sans-serif;
  --roboto-condensed: 'Roboto Condensed', sans-serif;
  --lato: 'Lato', sans-serif;

  --text-separation-50: 0.1em;
  --text-separation-100: 0.2em;

  --text-weight-ligth: 300;
  --text-weight-normal: 400;
  --text-weight-semi-bold: 600;
  --text-weight-bold: 800;

  // Colors
  --black: #000000;
  --soft-black: #3d3d3d;

  --white: #ffffff;
  --light-white: #f8f9ff;
  --soft-white: #e7e6f5;

  --soft-grey: #f1f1f1;
  --light-grey: #ebebf2;

  --blue: #035ee6;
  --blue-rgb: 3, 94, 230;
  --light-blue: #1a80e2;
  --light-blue-rgb: 26, 128, 226;
  --dark-blue: #10069f;

  // Colores V2: https://zpl.io/Vk1vzwv

  --royal-blue: #10069f; // Primario: usado principalmente en como fondos
  --blurple: #5a37d1; // Primario variante en claro
  --deep-blue: #00006f; // Primario variante en oscuro

  --cerulean-blue: #035ee6; // Secundario: usado principalmente en botones
  --cornflower: #658aff; // Secundario variante en claro
  --cobalt-blue: #0036b3; // Secundario variante en oscuro

  --dark-blue-grey: #122633; // Oscuro
  --slate: #3b4e5d; // Oscuro variante mas clara
  --dark-navy-blue: #00000c; // Oscuro variante mas ocura

  --dark-grey: #434343;
  --dark-grey-highlight: #959595;

  --silver: #ced5d8; // Claro
  --ice-blue: #f8f9ff; // Claro variante mas clara
  --cloudy-blue: #d4d3e0; // Claro variante mas ocura

  --orange: #d6553e;
  --orange-rgb: 214, 85, 62;
  --orange-tint: #da6651;
}

.btn-orange {
  background-color: var(--orange);
  color: white;

  &:hover {
    color: white;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--orange-rgb), .25);
  }
}

.btn-dark {
  background-color: var(--dark-grey);
  color: white;
  border: none;

  &:hover {
    background-color: var(--dark-grey-highlight);
    color: white;
  }
}

.mat-mdc-button,
.mat-mdc-raised-button {
  &.btn-big {
    height: 40px;
    border-radius: 8px;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

mat-datepicker-content.mat-datepicker-content {
  border-radius: 12px;
  padding: 24px 16px;
}

mat-calendar {
  .mat-calendar-content {
    &[ng-reflect-ng-switch="month"] {
      .mat-calendar-body-label[colspan="7"] {
        display: none;
      }
    }

    &[ng-reflect-ng-switch="year"] {
      .mat-calendar-body-label[colspan="4"] {
        display: none;
      }
    }

    &[ng-reflect-ng-switch="multi-year"] {
      .mat-calendar-body-label[colspan="4"] {
        display: none;
      }
    }

    .mat-calendar-body-label {
      visibility: hidden;
    }
  }

  &.with-custom-header {
    .mat-calendar-content {
      border: solid 1px #d2d2d2;
      border-top: none;
      margin: 8px;
      margin-top: 0;
      border-radius: 0 0 8px 8px;
    }
  }

  .mat-calendar-body-selected {
    background-color: var(--blue);
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(var(--blue-rgb), .3);
  }

  .mat-calendar-body-in-range::before {
    background-color: rgba(var(--blue-rgb), .2);
  }
}

label.required,
.label.required {
  &::after {
    content: '*';
    color: var(--danger);
    margin-left: 4px;
    font-size: 14px;
  }
}

.cdk-overlay-container .cdk-overlay-pane {
  &.no-min-width {
    mat-dialog-container {
      min-width: 0;
    }
  }

  &.not-agripago-dialog {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      border-radius: 18px;
      height: 272px;
    }
  }
}

.cdk-overlay-pane .mat-mdc-dialog-container {
  min-width: 35vw;
}

.cdk-overlay-pane.dialog-width-auto .mat-mdc-dialog-container {
  min-width: auto; //aplica al modal de cambio de precios de venta de granos

  .mdc-dialog__surface {
    border-radius: 18px;
  }
}

// Alertas
.alert-info-agd_agro {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 20px 20px 20px 25px;
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  text-align: left;

  mat-icon {
    color: var(--cornflower);
    margin-right: 10px;
    flex-shrink: 0;
  }

  &:before {
    content: '';
    width: 5px;
    height: 100%;
    background-color: var(--cornflower);
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (max-width: 575px) {
    padding: 10px 10px 10px 15px;
  }
}

.blurred-backdrop {
  -webkit-backdrop-filter: blur(4.5px);
  backdrop-filter: blur(4.5px);
  background-color: rgba(black, .2);
}

.light-box-shadow .mat-mdc-dialog-container {
  box-shadow: 0 3px 6px 0 rgba(black, 0.16);
}

button:focus {
  outline: none;
}

/* Customize Material Components */
mat-option {
  .mdc-list-item__primary-text {
    min-width: 0;
  }
}

.agd-context-menu.mat-mdc-menu-panel {
  max-height: 80vh;
  max-height: 80dvh;
}

mat-slide-toggle.mat-mdc-slide-toggle {
  .mdc-switch {
    .mdc-switch__track {
      height: 22px;
      border-radius: 11px;
    }

    &.mdc-switch--selected .mdc-switch__handle::after {
      background-color: #e4e4e4;
    }
  }

  .mdc-switch__icons {
    display: none;
  }
}

.light-tooltip {
  background: white;
  color: var(--blue) !important;
}

.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface {
    padding: 16px;

    &.mdc-dialog__surface {
      display: flex;
      justify-content: top;

      @media (max-width: 700px) {
        justify-content: center; //TO fix Modal in mobile view
        height:fit-content;
      }

      max-height: 80dvh;
      scroll-behavior: auto;
    }
  }
}

.venta-de-granos-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  &.mdc-dialog__surface {
    border-radius: 18px;
    padding: 20px 30px;
  }
}

.venta-de-granos-dialog.seuod-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  &.mdc-dialog__surface {
    max-width: 327px;
    padding-top: 48px;
  }
}

.venta-de-granos-dialog.TOS-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  &.mdc-dialog__surface {
    padding:30px;
    @media (max-width: 590px) {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.venta-de-granos-dialog.TOS-dialog{

  .mat-mdc-dialog-container{
    @media (max-width: 590px) {
      height: 100dvh;
    }

    .mdc-dialog__container{
        @media (max-width: 590px) {
          display: flex;
          flex-direction: column;
          min-height: 80vh;
          justify-content: flex-end;
        }
        @media (max-width: 380px) {
          min-height: 90vh;
        }
    }
  }
}


.font-small {
  font-size: 12px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.min-width-0 {
  min-width: 0;
}

.mat-mdc-select-panel .mat-mdc-option span {
  font-size: 13px;
  white-space: nowrap;
}

.mat-mdc-select-panel.mdc-menu-surface--open {
  display: inline-table;
  /* Revisar en Angular16 - Fixea que se no se corten los elementos del select por menor ancho */

  &.scrollable-selector {
    display: block;
    overflow: auto;
  }
}


.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel.mdc-menu-surface--open {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 240px;
  min-width: max-content;
}

/* Common style to all grids, delete after restyle is applied */
agd-filters-toolbar {
  margin: 0 -30px;
}

.mat-mdc-tooltip-panel-right .mat-mdc-tooltip::before {
  content: '◀';
  font-size: 18px;
  color: #000;
  left: -8px !important;
  top: calc(50% - 13.5px);
}

.mat-mdc-tooltip-panel-below .mat-mdc-tooltip::before {
  content: '▲';
  font-size: 18px;
  color: #000;
  top: -18px !important;
  left: calc(50% - 9px);

}


.mat-mdc-tooltip-panel-above .mat-mdc-tooltip::after {
  content: '▼';
  font-size: 18px;
  color: #000;
  bottom: -16px !important;
  left: calc(50% - 9px);
  position: absolute;
}

.mat-mdc-tooltip-panel-left .mat-mdc-tooltip::after {
  content: '▶';
  font-size: 18px;
  color: #000;
  right: -8px;
  top: calc(50% - 13.5px);
  position: absolute;
}

.mat-mdc-tooltip-panel{
  .mat-mdc-tooltip .mdc-tooltip__surface {
    background-color: #000;
    font-size: 11px;
    @media (max-width: 590px) {
      display:none;
    }
  }
}

.mat-mdc-tooltip-panel-right .mat-mdc-tooltip::before, .mat-mdc-tooltip-panel-left .mat-mdc-tooltip::after , 
.mat-mdc-tooltip-panel-below .mat-mdc-tooltip::before, .mat-mdc-tooltip-panel-above .mat-mdc-tooltip::after{
  @media (max-width: 590px) {
    display:none;
  }
}  

.emoji-mart-category .emoji-mart-emoji:hover:before {
  top: 3px;
  left: 3px;
}