/**
 * NAV FOR SUB PAGES
 */

nav.second-nav ul {
    height: 50px;
    padding: 0;
    margin: 0;

    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    list-style: none;
    line-height: 50px;

    font-family: var(--lato), sans-serif;
    font-size: 12pt;
    text-align: center;
    text-transform: uppercase;

    background-color: var(--white);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);

    li {
        display: inline-block;

        a {
            height: 50px;
            padding: 0 10px;
            display: block;
            color: #3D3D3D;
            border-bottom: 2px solid var(--white);

            &:hover,
            &.active-item {
                padding: 0 10px;
                border-bottom: 2px solid var(--light-blue);
                text-decoration: none;
                color: var(--blue);
                transition: all linear 0.3s;
            }
        }
    }
}
