/**
 * MAIN TABLE
 */

.main-table {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(16, 6, 159, 0.1);

  .mat-mdc-table {
    overflow-x: auto;
    font-family: var(--roboto);
    color: var(--light-blue);
    display: table;

    .mat-mdc-header-row {
      box-shadow: 0px 1px 2px -1px rgba(16, 6, 159, 0.2);
      min-height: 48px;
      background-color: white;
      display: table-row;
    }

    .mat-mdc-footer-row {
      background-color: #f9f9f9;
      font-weight: bold;
      display: table-row;
    }

    .mat-mdc-footer-row.table-footer-hidden {
      display: none;
    }

    .mat-mdc-row {
      border-bottom: 1px solid var(--soft-white);
      align-items: stretch;
      min-height: 40px;
      display:table-row;
    }

    .mat-mdc-header-cell {
      display: table-cell;
      vertical-align: middle;
      &:active,
      &:hover,
      &:focus {
        color: var(--white);
        background-color: var(--soft-black);

        .mat-sort-header-arrow {
          color: var(--white);
          background-color: var(--soft-black);
  
          .mat-sort-header-arrow {
            color: var(--white);
          }
        }
      }

      &.no-filter {
        > span {
          padding: 5px 10px;
        }
        span.mat-label{
          padding-right:15px;
        }
      }
    }

    .mat-mdc-header-cell,
    .mat-mdc-header-cell button {
      //white-space: nowrap;
      font-size: 9pt;
      text-transform: uppercase;
      white-space: inherit;
      color: #1a80e2;
      .dropdown-toggle::after {
        background: url('./../images/icon_more-options.png') no-repeat 0 0;
        width: 2px;
        height: 10px;
        border: none;
        vertical-align: baseline;
        position: absolute;
        right: 10px;
        top: 19px;
      }
    }

    .mat-mdc-header-cell .btn-group {
      width: 100%;

      &.text-right {
        button {
          text-align: right;
        }
      }
    }

    .mat-mdc-header-cell .boton {
      width: 100%;
      min-height: 48px;

      border: none;
      font-family: var(--roboto);
      color: #1a80e2;
      background-color: transparent;

      padding-right: 20px;
      padding-left: 10px;

      border-radius: 0px;

      text-align: inherit;

      &:active,
      &:hover,
      &:focus {
        background: var(--soft-black);
        border: none;
        color: var(--white);
      }

      > .header-label {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    mat-cell {
      font-size: 9pt;
      color: var(--soft-black);
      line-height: 100%;

      &.cdk-column-mail {
        text-transform: lowercase;
      }
      &.cdk-column-usuario {
        text-transform: none;
      }
    }

    .mat-mdc-header-cell,
    .mat-mdc-cell,
    .mat-mdc-footer-cell {
      font-size: 9pt;
      padding: 0 15px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      min-width: 164px;
      height: 40px;

      &.cdk-column-mail {
        min-width: 240px;
      }
      &.cdk-column-operaciones {
        min-width: 210px;
      }

      .btn-group,
      .mat-label {
        display: inline-block;
        margin: 0 auto;

        &.text-right {
          margin-right: 0;
        }
      }

      .mat-label {
        width: 100%;
        position: relative;

        .mat-sort-header-button {
          display: inline;
          width: 100%;
          min-height: 48px;
        }

        .mat-sort-header-arrow {
          position: absolute;
          right: 10px;
        }
      }

      .mat-label-wo-arrow {
        padding-right: 15px;
      }
    }

    .mat-mdc-cell {
      border-left: 0.5px solid var(--soft-white);
      padding-top: 5px;
      padding-bottom: 5px;

      &:last-child {
        border-right: 0.5px solid var(--soft-white);
        min-width:195px;/*seteado para matchear el ancho del menu desplegable*/
      }
    }

    .mat-mdc-header-cell {
      border-left: 2px solid var(--light-white);
      padding-left: 0;
      padding-right: 0;
      min-width: 164px;
      &:last-child {
        border-right: 2px solid var(--light-white);
        min-width: 195px;/*seteado para matchear el ancho del menu desplegable*/
      }
    }

    .mat-mdc-footer-cell {
      padding: 0px 17px 0px 0px;
      display: table-cell;
      vertical-align: middle;
      height: 40px;
    }



    // Align the cell's content to the left
    .cdk-column-insumo,
    .cdk-column-rubro,
    .cdk-column-forma,
    .cdk-column-acopio {
      text-align: left;
    }

    // Align the cell's content to the right
    .cdk-column-cartaDePorte,
    .cdk-column-kgEntregados,
    .cdk-column-kgMerma,
    .cdk-column-kgNetos,
    .cdk-column-kgAplicados,
    .cdk-column-kgAFijar,
    .cdk-column-kgTransferidos,
    .cdk-column-kgVendidos,
    .cdk-column-kgPendientes,
    .cdk-column-kgPactado,
    .cdk-column-kgFijados,
    .cdk-column-kgBrutos,
    .cdk-column-saldoPesos,
    .cdk-column-nroContrato,
    .cdk-column-nroContratoAFijar,
    .cdk-column-nroComprobante,
    .cdk-column-importe,
    .cdk-column-importeTotal,
    .cdk-column-ticket,
    .cdk-column-ticketOriginal,
    .cdk-column-ticketTransferencia,
    .cdk-column-nro1116A,
    .cdk-column-valor,
    .cdk-column-honorarios,
    .cdk-column-debe,
    .cdk-column-haber,
    .cdk-column-saldo,
    .cdk-column-precioPesos,
    .cdk-column-precioPesosOtros,
    .cdk-column-precioUsd,
    .cdk-column-precioUsdOtros,
    .cdk-column-cantidad,
    .cdk-column-monto,
    .cdk-column-cantidadEntregada,
    .cdk-column-cantidadFacturada,
    .cdk-column-grado,
    .cdk-column-precio {
      text-align: right;
    }

    .cdk-column-corredor,
    .cdk-column-vendedor {
      width: 50px;
      text-overflow: ellipsis;
      display: table-cell;
      }
  }
}

.no-results {
  opacity: 0.5;
}

.dropdown-item {
  font-size: 10pt;
  font-family: var(--roboto);
  color: #3d3d3d;
  padding: 8px 15px 8px 12px;
}

.btn-group:not(.dropdown-input) .dropdown-menu {
  padding: 0;
  background-color: var(--soft-black);
  color: var(--white);
  border-radius: 0 0 5px 5px;
  border: 0px;
  margin-top: 0px;

  li {
    font-size: 9pt;

    &:active,
    &:focus {
      color: white;
    }

    a,
    a:not([href]) {
      font-size: 9pt;
      color: var(--white);
      background: transparent;
      cursor: pointer;
      font-weight: normal;

      &:active,
      &:hover,
      &:focus {
        color: var(--light-white);
        background: transparent;
      }
    }
  }

  .icofont {
    margin-right: 5px;
  }
}

.table-footer-hidden {
  display: none;
}

.content-page {
  .main-table {
    position: relative;

    & > mat-table {
      min-height: 290px;
    }
    & > .table-status-container {
      width: 100%;
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.grid-footer-label-container {
  margin-bottom: 0;
  & > div {
    width: 100%;
    background-color: #f9f9f9;
    border-color: var(--soft-white);
    padding: 11px;
    margin-bottom: 0;
    & > span,
    a {
      font-size: 11px;
      font-family: var(--roboto);
      letter-spacing: var(--text-separation-50);
    }
    & > span {
      color: var(--gray);
    }
    .icon-close {
      cursor: pointer;
      font-size: 9pt;
      font-weight: bold;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }
  @media (max-width: 575px) {
    margin-top: 0;
  }
}

.table-modal-container {
  .mat-mdc-dialog-container {
    overflow-y: hidden;
  }
}



.mat-table-container {
  display: flex;
  flex-wrap: wrap; 
  width: 100%;
  overflow-x: auto;
  transform: rotateX(180deg);
  .mat-mdc-table {
      width: 100%;
      max-width: 100%;
      display: table;
      margin: 0px;
      transform: rotateX(180deg);
      order:1;
  }
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

