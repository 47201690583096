/**
 * PAGINADOR
 */
.main-table {

  .mat-mdc-table {
    background-color: transparent;
  }

  .mat-mdc-paginator {
    background-color: transparent;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 10pt;
    color: #4D4F5C;
    justify-content: space-between;

    .mat-mdc-paginator-container {
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;
      padding:10px 8px;

      @media screen and (max-width: 576px) {
        justify-content: stretch;

        .mat-mdc-paginator-page-size,
        .mat-mdc-paginator-page-size-label,
        .mat-mdc-paginator-range-actions,
        .mat-mdc-paginator-range-label {
          flex-grow: 1;
        }
      }

      .mat-mdc-paginator-page-size {
        width: auto;
        margin-right: 0;
        align-items: center;
        flex:50;
      }

      .mat-mdc-paginator-range-actions {
        justify-content: flex-end;
        .mat-mdc-paginator-range-label{
          margin: 0 20px 0 20px;
        }
        @media (max-width: 575px) {
          .mat-mdc-paginator-range-label {
            margin: 0 8px 0 5px;
          }
          .mat-mdc-icon-button {
            width: 30px;
          }
        }
      }
    }
  }

}

