/**
 * MODAL's
 */

.mat-mdc-dialog-container {
  padding: 16px!important;
  border-radius: 8px !important;

  .modal-header {
    .modal-title {
      @media(max-width:767px){ 	
        font-size: 12pt;
      } 
      font-size: 18pt;
      font-weight: 400;
    }
  }

  .modal-wrapper {
    @media(max-width:767px){ 	
      padding: 0;
      width: auto;
    }
    padding: 16px 0 16px;
  }

  table td, table th, .mat-mdc-cell, .mat-mdc-paginator {
    @media(max-width:767px){
      font-size: 8pt;
    } 
  }

  .mat-mdc-paginator-range-label {
    @media(max-width:767px){
      margin: 0;
    } 
  }

}

// Action modal style
.action-modal.cdk-overlay-pane {
  max-width: 100% !important;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  overflow: hidden;

  .mat-mdc-dialog-container {
    padding: 0 !important;

    .mat-mdc-dialog-surface {
      padding: 32px 24px;
    }
  }
}

@media(max-width: 489px){	
  .cdk-overlay-container {
    .cdk-global-overlay-wrapper .cdk-overlay-pane:not(.mat-mdc-snack-bar-handset):not(.action-modal) {
      max-height: 100vh !important;
      max-width: 100vw !important;
      height: 100vh;
      width: 100vw;

      .mat-mdc-dialog-container {
        border-radius: 0 !important;
        padding: 0 !important;
      }
    }
  }
  
  .cdk-global-overlay-wrapper .cdk-overlay-pane.no-min-width:not(.mat-mdc-snack-bar-handset) {
        max-width: 85vw !important;
        max-height: fit-content !important; 
  }
}